<template>
  <transition-group
    appear
    :class="[
      'vertical-bar-chart',
      { '--no-labels': !yLabel && !xLabel },
    ]"
    mode="out-in"
    name="fade"
    tag="div"
  >
    <template>
      <span
        v-if="yLabel"
        key="yLabel"
        class="vertical-bar-chart__y-label"
      >
        {{ yLabel }}
      </span>
      <span
        v-if="xLabel"
        key="xLabel"
        class="vertical-bar-chart__x-label"
      >
        {{ xLabel }}
      </span>

      <transition-group
        key="bars"
        :class="[
          'vertical-bar-chart__bars',
          {
            '--y-label': yLabel,
            '--x-label': xLabel,
          },
        ]"
        name="bars"
        :style="{
          gridTemplateColumns: `repeat(${bars.length}, ${barWidth})`,
          columnGap: gutter,
        }"
        tag="div"
      >
        <VerticalBar
          v-for="(bar, index) in bars"
          :key="index + bar.value"
          :color="bar.color"
          :height="height"
          :label="bar.label"
          :show-value="showValue"
          :total="total"
          :value="bar.value"
        />
      </transition-group>
    </template>
  </transition-group>
</template>

<script>
import VerticalBar from '@/components/VerticalBar'

export default {
  name: 'VerticalBarChart',
  components: {
    VerticalBar,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    height: {
      type: String,
      default: '320px',
    },
    barWidth: {
      type: String,
      default: '64px',
    },
    gutter: {
      type: String,
      default: '32px',
    },
    showValue: Boolean,
    yLabel: {
      type: String,
      default: '',
    },
    xLabel: {
      type: String,
      default: '',
    },
    bars: {
      type: Array,
      required: true,
      default: () => [
        {
          value: 0,
          label: '',
          color: '',
        },
      ],
    },
  },
}
</script>

<style lang="sass" scoped>
$label-border: 1px dotted tint-color($color-ink-lighter, 50%)

.vertical-bar-chart
  display: grid
  grid-template-areas: "y-axis bars" ". x-axis"
  grid-template-columns: max-content  1fr
  align-items: center
  justify-items: center
  justify-content: center
  box-sizing: border-box

  &.--no-labels
    display: flex

  &__bars
    display: inline-grid
    align-items: flex-end
    justify-content: center
    grid-area: bars

    &.--y-label
      padding: 0 16px

  &__y-label
    grid-area: y-axis
    writing-mode: vertical-rl
    transform: rotate(180deg) translateY(-40px)
    padding-left: 8px
    white-space: nowrap
    border-left: $label-border
    height: 100%

  &__x-label
    grid-area: x-axis
    padding-top: 8px
    border-top: $label-border
    margin-top: 16px
    width: calc(100% + 16px)
    transform: translateX(-16px)

  &__x-label,
  &__y-label
    color: $color-ink-lighter
    +flex-center

  &__loading
    padding: 24px
    width: 100%
    height: 100%
    +flex-center
</style>
