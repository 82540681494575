<template>
  <div class="sas-chart-legend">
    <div
      class="sas-chart-legend__color"
      :style="{ background: color }"
    />
    <span class="sas-chart-legend__text">
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'ChartLegend',
  props: {
    color: {
      type: String,
      default() {
        return this.$tokens.color_primary
      },
    },
  },
}
</script>

<style lang="sass">
.sas-chart-legend
  display: flex
  align-items: center
  justify-content: flex-start

  &__color
    width: 20px
    height: 20px
    border-radius: 4px
    margin-right: 8px

    +mq-s
      width: 16px
      height: 16px

  &__text
    font-size: 14px
    font-weight: 500
    line-height: 1
    margin-bottom: -1px
    color: $color-ink

</style>
