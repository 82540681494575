var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition-group',{class:[
    'vertical-bar-chart',
    { '--no-labels': !_vm.yLabel && !_vm.xLabel } ],attrs:{"appear":"","mode":"out-in","name":"fade","tag":"div"}},[[(_vm.yLabel)?_c('span',{key:"yLabel",staticClass:"vertical-bar-chart__y-label"},[_vm._v(" "+_vm._s(_vm.yLabel)+" ")]):_vm._e(),(_vm.xLabel)?_c('span',{key:"xLabel",staticClass:"vertical-bar-chart__x-label"},[_vm._v(" "+_vm._s(_vm.xLabel)+" ")]):_vm._e(),_c('transition-group',{key:"bars",class:[
        'vertical-bar-chart__bars',
        {
          '--y-label': _vm.yLabel,
          '--x-label': _vm.xLabel,
        } ],style:({
        gridTemplateColumns: ("repeat(" + (_vm.bars.length) + ", " + _vm.barWidth + ")"),
        columnGap: _vm.gutter,
      }),attrs:{"name":"bars","tag":"div"}},_vm._l((_vm.bars),function(bar,index){return _c('VerticalBar',{key:index + bar.value,attrs:{"color":bar.color,"height":_vm.height,"label":bar.label,"show-value":_vm.showValue,"total":_vm.total,"value":bar.value}})}),1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }