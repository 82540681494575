export default {
  computed: {
    mq_xs() {
      return this.$mq === 'x_small'
    },

    mq_s() {
      return ['x_small', 'small'].includes(this.$mq)
    },

    mq_m() {
      return ['x_small', 'small', 'medium'].includes(this.$mq)
    },

    mq_l() {
      return ['x_small', 'small', 'medium', 'large'].includes(this.$mq)
    },

    mq_xl() {
      return ['x_small', 'small', 'medium', 'large', 'x_large'].includes(
        this.$mq,
      )
    },
  },
}
