<template>
  <div class="vertical-bar__container">
    <div
      class="vertical-bar__wrapper"
      :style="{
        width,
        height,
      }"
    >
      <div
        class="vertical-bar"
        :style="{
          height: `${percentage}%`,
          background: color,
          color: color,
          borderRadius: radius,
        }"
      >
        <span
          v-if="showValue"
          class="vertical-bar__value"
          :style="{ color }"
        >
          {{ animatedPercentageRound }}%
        </span>
      </div>
    </div>

    <small
      v-if="label"
      class="vertical-bar__label"
    >
      {{ label }}
    </small>
  </div>
</template>

<script>
import { TweenMax, Expo } from 'gsap'

export default {
  name: 'VerticalBar',

  props: {
    width: {
      type: String,
      default: '100%',
    },
    total: {
      type: Number,
      default: 0,
    },
    height: {
      type: String,
      default: '100%',
    },
    value: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
    },
    radius: {
      type: [Number, String],
      default: '8px 8px 0 0',
    },
    showValue: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
    label: {
      type: String,
    },
  },

  data() {
    return {
      animatedPercentage: 0,
    }
  },

  computed: {
    percentage() {
      const percentageModifier = 100

      return (this.value / this.total) * percentageModifier
    },

    animatedPercentageRound() {
      return this.percentage.toLocaleString('pt-br', {
        maximumFractionDigits: 1,
      })
    },
  },

  mounted() {
    TweenMax.to(this.$data, 1, {
      animatedPercentage: this.percentage,
      ease: Expo.easeInOut,
    })
  },
}
</script>

<style lang="sass">

.vertical-bar
  position: relative
  box-sizing: border-box
  transition: all 500ms ease-in-out
  background: $color-ink-light
  width: 100%

  &__container
    height: 100%
    display: inline-flex
    align-items: center
    flex-direction: column
    justify-content: flex-end
    padding-top: 24px
    box-sizing: border-box
    transition: all 400ms ease-in-out

  &__wrapper
    display: flex
    align-items: flex-end

  &__value
    font-weight: 600
    font-size: 13px
    letter-spacing: .44px
    color: $color-ink-light
    display: block
    width: 100%
    text-align: center
    position: relative
    top: -24px

  &__label
    color: $color-ink-light
    margin-top: 8px
    text-align: center
    white-space: nowrap
    font-size: $font-size-xxs
</style>
